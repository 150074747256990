import React from 'react'
import { Col, Row } from 'react-bootstrap'
import { StaticImage } from 'gatsby-plugin-image'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'

const CommunityPage = () => {
  return (
    <Layout hero={<HeroImage title="Australian Heritage" />} mainContainer={{fluid: "xxl"}}>
      <Row className="d-flex justify-content-center content-row mt-4">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="title-container">
                <h1 className="title">EVERYTHING IN STEEL SINCE 1967</h1>
              </div>
            </Col>
            <Col sm={6}>
              <p className="mb-3">
                Metalcorp is an institution in the Australian bush, 
                dating back to the company's original incarnation from Ashley and Moore in 1967. 
                Metalcorp produces a range of rural products designed to withstand harsh environments 
                from coastal locations to inland farms, Metalcorp is your "Rural Brand for the Land."
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center grey-row content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">AUSTRALIAN HISTORY</h1>
              </div>
              <p className="mb-3">
                There has been mergers and rebranding of the business since that time with Ashley and Moore and M.I. 
                Steel combining to form MI Steel in NSW and QLD. 
                Metalcorp was formed from these two businesses and then purchased by 
                the Smorgon Group in 2001 to then become part of the BlueScope group of businesses.
              </p>
              <p>We're committed to supporting Australian farmers now and into the future.</p>
            </Col>
            <Col sm={6}>
              <StaticImage 
                src="../../images/cattle-yard.jpeg" 
                alt="Cattle yard"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5 d-flex justify-content-center content-row">
        <Col xs={10}>
          <Row className="d-flex align-items-center">
            <Col sm={6}>
              <StaticImage 
                src="../../images/farmer-facing-back.jpeg" 
                alt="Hero image"
                width={480}
                height={360}
                placeholder="blurred" />
            </Col>
            <Col sm={6}>
              <div className="title-container mb-3">
                <h1 className="title">
                  RURAL QUALITY FOR RURAL AUSTRALIA
                </h1>
              </div>
              <p className="mb-3">
                Metalcorp's knowledge of rural Australia is one of our greatest strengths. 
                Through our dedicated team of Rural Managers, 
                we are well-versed in the challenges for rural Australia and 
                understand the quality that steel products need to suit some very harsh farming environments.
              </p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  )
}

export default CommunityPage

export const Head = () => <title>Australian Heritage | Metalcorp</title>
